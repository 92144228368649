import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import leftArrow from "../../assets/leftArrow.png";
import clockIcon from "../../assets/clock.png";
import clockIcon2 from "../../assets/clockIcon.svg";
import loadingGif from "../../assets/loading2.gif";

import Navbar from "../../Dashboard/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../Utils/config";

function ViewDetails() {
  // const navigate = useNavigate();

  const [dailyWorkingDetails, setDailyWorkingDetails] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  let phoneNum = localStorage.getItem("superonUserPhNum");
  const params = useParams();

  let punchIn = params.punchin;
  let punchOut = params.punchout;
  let date = params.date;

  const convertTimeTo12HourFormat = (time) => {
    if (!time) return;
    let hours = parseInt(time.substring(0, 2));
    let minutes = time.substring(3, 5);
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const reverseDate = (date) => {
    const inputDate = new Date(date);
    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getFullYear().toString();

    const outputDate = `${day}-${month}-${year}`;
    console.log(outputDate); // Output: 25-04-2023

    return outputDate;
  };

  const convertIntoCamelCase = (name) => {
    let formattedName = name.charAt(0) + name.slice(1).toLowerCase();

    return formattedName;
  };

  const getDailyWorkingDetails = async () => {
    setShowLoading(true);
    let data = {
      mobile: phoneNum,
      // visitdate: "2023-4-13",
      visitdate: date,
    };

    console.log(data);

    console.log(data);
    let response = await axios.post(config.ipAddress + `/visitbydate`, data);

    console.log(response.data.data);
    let allData = response?.data?.data;
    let filterData = allData.filter((item) => {
      return item.punchouttime !== null;
    });
    setDailyWorkingDetails(filterData);
    setShowLoading(false);
  };

  useEffect(() => {
    getDailyWorkingDetails();
  }, []);

  return (
    <div className="h-screen">
      {/* <div className="bg-orange-light flex gap-4 items-center px-5 py-5">
        <img
          src={leftArrow}
          alt="leftArrow-logo"
          className="w-6 h-5"
          onClick={() => navigate("/attendance/record")}
        />
        <h1 className="text-white font-semibold text-lg">
          View Attendance Details
        </h1>
      </div> */}

      {/* Navbar */}
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>

      {/* details */}

      <section className="bg-[#fafafa] min-h-[89.2%] py-4 px-3 ">
        <main className=" h-full ">
          <section className="rounded-[10px] shadow-6xl py-4 bg-white border-[1px] border-gray-300">
            <div className="flex gap-2 ml-4 bg-white">
              <div className="bg-[#f37022] w-[2px] rounded-full"></div>
              <div className="">
                <h1 className="text-lg ">Punch In and Out Details</h1>
              </div>
            </div>

            <div className="flex justify-center gap-8 bg-white px-4 mt-3 ">
              <div>
                <p className="text-center text-md text-orange-light">
                  Date <br />
                  <span className="font-medium text-black text-sm">
                    {reverseDate(date)}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-center text-md text-orange-light">
                  Punch In <br />
                  <span className="font-medium text-black text-sm">
                    {punchIn}
                  </span>
                </p>
              </div>

              <div>
                <p className="text-center text-md text-orange-light">
                  Punch Out <br />
                  <span className="font-medium text-black text-sm">
                    {punchOut}
                  </span>
                </p>
              </div>
            </div>
          </section>

          {/* <section className="rounded-[10px] shadow-6xl mt-3 pt-4 bg-white border-[1px] border-gray-200">
            <div className="flex gap-2 ml-4 bg-white ">
              <div className="bg-[#f37022] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Visit Details</h1>
              </div>
            </div>

            {dailyWorkingDetails.length
              ? dailyWorkingDetails.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" bg-white px-4 mt-3 rounded-[4px] "
                    >
                      <div className="flex flex-col gap-1">
                        <h1 className="text-md  text-orange-light mb-1">
                          {index + 1} :
                          <span className="text-black ml-1">
                            {convertIntoCamelCase(item.dealername)}
                          </span>
                        </h1>
                        <div className="flex items-center ml-5 gap-2">
                          <img
                            src={clockIcon}
                            alt="clock-logo"
                            className="w-4"
                          />
                          <p className=" text-sm text-black">
                            <span className="font-medium ">
                              {convertTimeTo12HourFormat(item.punchintime)}
                            </span>{" "}
                            -{" "}
                            <span className="font-medium">
                              {convertTimeTo12HourFormat(item.punchouttime)}
                            </span>
                          </p>
                        </div>
                        <p className=" text-base text-black  ml-[19px] my-2">
                          Remarks :{" "}
                          <span className="font-medium   ">{item.remark}</span>
                        </p>
                      </div>

                      <hr className="h-[0px] bg-gray-400" />
                    </div>
                  );
                })
              : // <h1 className="font-semibold py-4 ml-6 text-lg">
                //   No Data to Display!
                // </h1>
                ""}

          
          </section> */}
          <section className="">
            {/* <div className="flex gap-2 ml-4 bg-white ">
              <div className="bg-[#f37022] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Visit Details</h1>
              </div>
            </div> */}

            {dailyWorkingDetails.length
              ? dailyWorkingDetails.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" bg-white mt-3 rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300"
                    >
                      <div className="flex flex-col gap-1 py-3   ">
                        <div className="flex gap-2 bg-white ">
                          <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                          <div className="">
                            <h1 className="text-md ">Visit {index + 1}</h1>
                          </div>
                        </div>
                        <h1 className="text-md  text-orange-light ">
                          <span className="text-black ml-3">
                            {convertIntoCamelCase(item.dealername)}
                          </span>
                        </h1>
                        <div className=" ml-3">
                          <img
                            src={clockIcon2}
                            alt="clock-logo"
                            className="w-4 inline-block"
                          />
                          <p className=" text-sm text-black inline-block ml-2">
                            {convertTimeTo12HourFormat(item.punchintime)} -{" "}
                            {convertTimeTo12HourFormat(item.punchouttime)}
                            {/* <span className="font-medium ">
                              {convertTimeTo12HourFormat(item.punchintime)}
                            </span>{" "}
                            -{" "}
                            <span className="font-medium">
                              {convertTimeTo12HourFormat(item.punchouttime)}
                            </span> */}
                          </p>
                        </div>
                        <p className=" text-base text-black  ml-3 ">
                          Remarks :{" "}
                          <span className="font-medium   ">{item.remark}</span>
                        </p>
                      </div>

                      {/* <hr className="h-[0px] bg-gray-400" /> */}
                    </div>
                  );
                })
              : // <h1 className="font-semibold py-4 ml-6 text-lg">
                //   No Data to Display!
                // </h1>
                ""}
          </section>

          {/* <div className="mt-4 bg-white px-4 py-3 rounded-[4px] shadow-lg">
          <h1 className="text-lg font-semibold text-orange-light mb-2">
            Visit 1 : <span className="text-black ml-3">Virat Kholi</span>
          </h1>
          <div className="space-y-3 mb-2">
            <p className=" text-base text-black font-semibold">
              Timing :
              <span className="font-medium text-[#545454] ml-3">9:05 AM </span>{" "}
              - <span className="font-medium text-[#545454]">9:05 PM</span>
            </p>

            <p className=" text-base text-black font-semibold">
              <span className="font-medium  text-[#545454] ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
                ad est alias rem?
              </span>
            </p>
          </div>
          <hr className="h-[1px] bg-gray-400" />
        </div>

        <div className="mt-4 bg-white px-4 py-3 rounded-[4px] shadow-lg">
          <h1 className="text-lg font-semibold text-orange-light mb-2">
            Visit 2 : <span className="text-black ml-3">Rohit Sharma</span>
          </h1>
          <div className="space-y-3 mb-2">
            <p className=" text-base text-black font-semibold">
              Timing :
              <span className="font-medium text-[#545454] ml-3">9:05 AM </span>{" "}
              - <span className="font-medium text-[#545454]">9:05 PM</span>
            </p>

            <p className=" text-base text-black font-semibold">
              <span className="font-medium  text-[#545454] ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
                ad est alias rem?
              </span>
            </p>
          </div>
          <hr className="h-[1px] bg-gray-400" />
        </div>

        <div className="mt-4 bg-white px-4 py-3 rounded-[4px] shadow-lg">
          <h1 className="text-lg font-semibold text-orange-light mb-2">
            Visit 3 : <span className="text-black ml-3">Alex Hales</span>
          </h1>
          <div className="space-y-3 mb-2">
            <p className=" text-base text-black font-semibold">
              Timing :
              <span className="font-medium text-[#545454] ml-3">9:05 AM </span>{" "}
              - <span className="font-medium text-[#545454]">9:05 PM</span>
            </p>

            <p className=" text-base text-black font-semibold">
              <span className="font-medium  text-[#545454] ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
                ad est alias rem?
              </span>
            </p>
          </div>
          <hr className="h-[1px] bg-gray-400" />
        </div> */}

          {/* <div className="mt-4 bg-white px-4 py-3 rounded-[4px] shadow-lg">
          <h1 className="text-lg font-semibold text-orange-light mb-2">
            Visit 2
          </h1>
          <div className="space-y-3 mb-2">
            <p className="text-md font-normal text-[#545454]">
              <span className="text-black font-semibold mr-3">Task :</span>{" "}
              Lorem ipsum dolor, sit amet
            </p>

            <h2 className="text-md font-normal text-[#545454]">
              <span className="text-base font-semibold text-black mr-3">
                Dealer Name :
              </span>
              Alex Heles
            </h2>

            <p className=" text-base text-black font-semibold">
              Punch In :
              <span className="font-medium text-[#545454]"> 9:05 AM</span>
            </p>
            <p className=" text-base text-black font-semibold">
              Punch Out :
              <span className="font-medium  text-[#545454]"> 9:05 PM</span>
            </p>

            <p className=" text-base text-black font-semibold">
              Message :
              <span className="font-medium  text-[#545454] ml-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
                ad est alias rem?
              </span>
            </p>
          </div>
          <hr className="h-[1px] bg-gray-400" />
        </div> */}
        </main>
      </section>

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default ViewDetails;
