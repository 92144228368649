import React, {useEffect, useState} from 'react'
import "./Style.css"
import resetLogo from "../../assets/reset.svg";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/icon-cancel.svg";
import loadingGif from "../../assets/loading2.gif";
import Datepicker from "react-datepicker";
import axios from "axios";
import config from "../Utils/config";
import ErrorPopup from '../popupModal/ErrorPopup';

const CloseSSLeads = () => {
    const phoneNum = localStorage.getItem("superonUserPhNum");
    const [selectedDateStart, setSelectedDateStart] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    
    const [message, setMessage] = useState("");
    const [competitorHistory, setCompetitorHistory] = useState([]);
    const [remark,setRemark] = useState({})

    const getSelectedWorkingDetails = async () => {
        if (!(selectedDateEnd && selectedDateStart)) {
          setMessage("Please select date!");
          setShowErrorModal(true);
          return;
        }
        function validateDates(from, to) {
          var fromDate = new Date(from);
          var toDate = new Date(to);
    
          if (toDate < fromDate) {
            return false;
          }
    
          return true;
        }
        let value = validateDates(selectedDateStart, selectedDateEnd);
        if (!value) {
          // return alert("please enter a valid date");
    
          setMessage("please enter a valid date");
          setShowErrorModal(true);
          return;
        }
    
        // if (
        //   !(month && year) ||
        //   month === "Select Month" ||
        //   year === "Select Year"
        // ) {
        //   return alert("Please Enter Month and Year");
        // }
    
        if (!phoneNum) {
          return;
        }
    
        setShowLoading(true);
        let data = {
          mobile: phoneNum,
          fromdate: covertDateString(selectedDateStart),
          todate: covertDateString(selectedDateEnd),
        };
    
        console.log(data);
    
        try {
          let response = await axios.post(
            config.ipAddress + `/opensslead-dar-search`,
            data
          );
    
          console.log('competitor data',response?.data?.data);
          let allData = response?.data?.data;
    
          console.log(allData);
          let filterData = allData.filter((item) => {
            return item.punchouttime !== null;
          });
    
          setCompetitorHistory(allData);
          setShowLoading(false);
        } catch (error) {
          setMessage("Network Error!");
          setShowErrorModal(true);
          setShowLoading(false);
        }
    };
    const getDailyWorkingHistory = async () => {
      setShowLoading(true);
  
      // Get the current date
      var currentDate = new Date();
      
      const fromDate = new Date(currentDate);
      fromDate.setDate(currentDate.getDate() - 7);
      const fromDateString = fromDate.toISOString().split('T')[0];
      // Get the previous month's 1st date
      var previousMonthFirstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      console.log('from Date',previousMonthFirstDate)
      // Format the dates
      var currentDateString =
        currentDate.getFullYear() +
        "-" +
        (currentDate.getMonth() + 1) +
        "-" +
        currentDate.getDate();
      var previousMonthFirstDateString =
        previousMonthFirstDate.getFullYear() +
        "-" +
        (previousMonthFirstDate.getMonth() + 1) +
        "-" +
        previousMonthFirstDate.getDate();
  
      let data = {
        mobile: phoneNum,
        fromdate: fromDateString,
        todate: currentDateString,
      };
      console.log(data);
  
      try {
        let response = await axios.post(
          config.ipAddress + `/opensslead-dar-search`,
          data
        );
        console.log(response?.data?.data);
  
        let allData = response?.data?.data;
        let filterData = allData.filter((item) => {
          return item.punchouttime !== null;
        });
        setCompetitorHistory(allData);
        setShowLoading(false);
      } catch (error) {
        setShowLoading(false);
      }
    };
    const covertDateString = (dateString) => {
        // const dateString = "2023-04-06T17:12:01";
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const outputString = `${year}-${month}-${day}`;
    
        console.log(outputString); // Output: "2023-4-6"
    
        return outputString;
    };

    const handleChangeRemark = (e,id) => {
      const {value} = e.target;
      setRemark({
        ...remark,
        [id]:value
      })
      console.log('remark obj',remark)
    }

    const handleSubmitRemark = (id) => {
      // console.log(
      const data =  {
          visitid: id,
          remark: remark[id]
        }
      // )
      try {
        const response = axios.post(config.ipAddress + `/dealer/addcompteitorremark`,data)
        setRemark({
          ...remark,
          [id]:""
        })
        getDailyWorkingHistory()
      } catch (error) {
        
      }
      
    }
    useEffect( ()=> {
      getDailyWorkingHistory()
    },[])

  return (
    <section className="bg-[#fafafa] min-h-[89.2%] py-4 px-3 ">
        <main className=" h-full ">
            <div className="py-4  shadow-6xl rounded-[10px] border-[1px] border-gray-300 bg-white">
                <div className="flex gap-2  ml-4 ">
                    <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                    <div className="">
                        <h1 className="text-lg ">Close SS Leads</h1>
                    </div>
                </div>

                <div className="pt-4 pr-4 pl-[26px]">
                    <div className="w-full flex justify-between gap-3">
                        <Datepicker
                        // portalId="root-portal"
                        popperClassName="some-custom-class1"
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: { behavior: ["bottom"] },
                            preventOverflow: { enabled: true },
                            hide: { enabled: false },
                        }}
                        selected={selectedDateStart}
                        onChange={(date) => setSelectedDateStart(date)}
                        dateFormat={"yyyy/MM/dd"}
                        placeholderText="From"
                        // showYearDropdown
                        wrapperClassName="datePicker"
                        ></Datepicker>

                        <Datepicker
                        portalId="root-portal"
                        popperClassName="some-custom-class2"
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: { behavior: ["bottom"] },
                            preventOverflow: { enabled: true },
                            hide: { enabled: false },
                        }}
                        selected={selectedDateEnd}
                        onChange={(date) => setSelectedDateEnd(date)}
                        dateFormat={"yyyy/MM/dd"}
                        placeholderText="To"
                        // showYearDropdown
                        wrapperClassName="datePicker"
                        ></Datepicker>
                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                        <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                        <img
                            src={resetLogo}
                            alt="submit-logo"
                            className="w-4 "
                            onClick={() => window.location.reload()}
                        />
                        </div>
                        <div className="border-[1px] flex justify-end  border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                        <img
                            src={submitLogo}
                            alt="submit-logo"
                            className="w-[18px] mx-auto "
                            onClick={() => getSelectedWorkingDetails()}
                        />
                        </div>
                    </div>
                </div>
            </div>
            {competitorHistory?.length ?
              competitorHistory?.map( (item) => {
                return (
                  <>
                    {item?.visits?.length ?
                      item?.visits?.filter((comp) => comp?.visitoption.toLowerCase() === "competitor" && comp?.salesleadremark ).map((visit)=> (

                        <section className="rounded-[10px] shadow-6xl py-4 bg-white border-[1px] border-gray-300 mt-3" key={visit.visitid}>
                          <div className="flex gap-2 ml-4 bg-white">
                              <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                              <div className="">
                                  <h1 className="text-lg">{visit?.dealer}</h1>
                              </div>
                          </div>
                          <div className="ss-leads-info">
                              <ul>
                                  <li>
                                      <div className='list-heading'>Date <span>:</span></div>
                                      <div className='desc'>{visit?.visitdate}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Type <span>:</span></div>
                                      <div className='desc'>{visit?.type}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Mobile <span>:</span></div>
                                      <div className='desc'>{visit?.dealermobile}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Location <span>:</span></div>
                                      <div className='desc'>{visit?.dealerlocation}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Time <span>:</span></div>
                                      <div className='desc'>{visit?.punchintime}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Competitor <span>:</span></div>
                                      <div className='desc'>Yes</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Brand Name <span>:</span></div>
                                      <div className='desc'>{visit?.competitorbrandname}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Buying From <span>:</span></div>
                                      <div className='desc'>{visit?.buyingfrom}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>SS Remark <span>:</span></div>
                                      <div className='desc'>{visit?.extra5}</div>
                                  </li>
                                  <li>
                                      <div className='list-heading'>Remark <span>:</span></div>
                                      <div className='desc'>{visit?.salesleadremark}</div>
                                  </li>
                              </ul>
                          </div>
                        </section>
                      ))
                      :
                      ''
                    }
                    
                  </>
                )
              })
              :
              ''
            }
            
            <section className=""></section>
        </main>
    </section>
  )
}

export default CloseSSLeads
