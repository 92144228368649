import React, {useEffect, useState} from 'react'
// import {
//     Accordion,
//     AccordionHeader,
//     AccordionBody,
//   } from "@material-tailwind/react";
import "./Style.css"
import IconLoader from "../../assets/loading2.gif"
import loadingGif from "../../assets/loading2.gif";
import { v4 as uuidv4 } from "uuid"
import moment from 'moment';
import axios from "axios";
import config from '../Utils/config';
import ErrorPopup from '../popupModal/ErrorPopup';

const WeeklyPlaning = () => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [collapsed,setCollapsed]=useState("");

    const [weekData,setWeekData] = useState([
        {weekday: 'Monday', area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''},
        {weekday: 'Tuesday', area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''},
        {weekday: 'Wednesday', area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''},
        {weekday: 'Thursday', area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''},
        {weekday: 'Friday', area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''},
        {weekday: 'Saturday', area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''}
    ])
    const [imprest,setImprest] = useState({
        imprest_needed: 'No',
        previous_imprest_cleared: 'No',
        // number_of_outstation_days: '',
        // area_of_operation: '',
        // weekly_imprest_request: ''
    });
    const [errorObj,setErrorObj] = useState({});
    const [weeklyPlanStatus,setWeeklyPlanStatus] = useState(99);
    const [result,setResult]=useState("");
    const [isCurrentDay,setIsCurrentDay] = useState('');
    const [loader,setLoader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [message, setMessage] = useState("");

    const handleOpen = (value) => {
        if(collapsed===value){
            setCollapsed("")
        }
        else{
            setCollapsed(value);
        }
    };
    const changeHandler = (e,item,weekday) => {
        const {name,value} = e.target
        // setWeekData({
        //     ...weekData,
        //     [name]:value
        // })
        console.log('item',name,value, item, weekday);

        const currentData = [...weekData]
        const updatedData = currentData.map((item) => {
            if (item.weekday === weekday) {
                return { ...item, [name]: value};
            }
            return item;
        });
        setWeekData(updatedData);
        console.log('check handler',item)
    };
    const changeImprestHandler = (e) => {
        const {name,value} = e.target
        if(name==="imprest_needed"){
            setImprest((prevImprest) => ({
                ...prevImprest,
                // previous_imprest_cleared: prevImprest.imprest_needed === "No" ? "No" : prevImprest.previous_imprest_cleared,
                area_of_operation: "",
                number_of_outstation_days: "",
                previous_imprest_cleared: "No",
                weekly_imprest_request: "",
                [name]: value
            }));
        }
        else if(name==="previous_imprest_cleared"){
            setImprest((prevImprest) => ({
                ...prevImprest,
                // previous_imprest_cleared: prevImprest.imprest_needed === "No" ? "No" : prevImprest.previous_imprest_cleared,
                area_of_operation: "",
                number_of_outstation_days: "",
                weekly_imprest_request: "",
                [name]: value
            }));
        }
        else{
            setImprest((prevImprest) => ({
                ...prevImprest,
                previous_imprest_cleared: prevImprest.imprest_needed === "No" ? "No" : prevImprest.previous_imprest_cleared,
                [name]: value,
            }));
        }
      
    }
    console.log('imprest val',imprest);
    const handleImprest = (e) => {
        setImprest({
            ...imprest, previous_imprest_cleared: e.target.value
        })
    }
    console.log('imprest radio', imprest)
    const getNextWeekDates = (today) => {
        const nextWeekDates = [];
        // Get current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = today.getDay();
        // Calculate the number of days until the next Sunday (start of next week)
        const daysUntilSunday = 7 - dayOfWeek;
        // Get the upcoming Sunday date
        const upcomingSunday = new Date(today);
        upcomingSunday.setDate(today.getDate() + daysUntilSunday);
        // Add each date for the next week (Sunday to Saturday)
        for (let i = 0; i < 7; i++) {
          const nextDate = new Date(upcomingSunday);
          nextDate.setDate(upcomingSunday.getDate() + i);
          nextWeekDates.push(moment(nextDate).format("YYYY-M-D"));
        }
        return nextWeekDates;
    };
    const getCurrentWeekDates = (today) => {
        const currentWeekDates = [];
        // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = today.getDay();
        // Calculate the date of the most recent Sunday (start of the week)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - dayOfWeek); // Move back to Sunday
        // Add each date for the current week (Sunday to Saturday)
        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startOfWeek);
          currentDate.setDate(startOfWeek.getDate() + i); // Increment each day
          currentWeekDates.push(moment(currentDate).format("YYYY-M-D"));
        }
        return currentWeekDates;
    };
    const handleSubmit = async () => {
        let error = {}
        let showError = false;
        console.log(weekData)
        for (let i = 0; i <= weekData.length; i++) {
            for (let key in weekData[i]) {
                if (!weekData[i][key]) {
                    showError = true
                    const name = weekData[i]?.weekday
                    error = {
                    ...error,
                        [name]: {
                            ...error[name],
                            [key]: true,
                        },
                    }
                }
            }
        }
        // Validate imprest fields
        if(imprest?.imprest_needed === "Yes" && imprest?.previous_imprest_cleared === "Yes") {
            if (!imprest.number_of_outstation_days) {
                showError = true;
                error = {
                    ...error,
                    imprest: {
                        ...error.imprest,
                        number_of_outstation_days: true,
                    },
                };
            }
            if (!imprest.area_of_operation) {
                showError = true;
                error = {
                    ...error,
                    imprest: {
                        ...error.imprest,
                        area_of_operation: true,
                    },
                };
            }
            if (!imprest.weekly_imprest_request) {
                showError = true;
                error = {
                    ...error,
                    imprest: {
                        ...error.imprest,
                        weekly_imprest_request: true,
                    },
                };
            }
        }
        // if (!imprest.previous_imprest_cleared) {
        //     showError = true;
        //     error = {
        //         ...error,
        //         imprest: {
        //             ...error.imprest,
        //             previous_imprest_cleared: true,
        //         },
        //     };
        // }
        
        let dateArr = [];
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const currentDayName = days[currentDay];
        console.log('current Day',days[currentDay])

        if(currentDayName === "Thursday" || currentDayName === "Friday" || currentDayName === "Saturday" || currentDayName === "Sunday") {
            dateArr= getNextWeekDates(currentDate);
            console.log('if',dateArr)
        } else {
            dateArr= getCurrentWeekDates(currentDate);
            console.log('else',dateArr)
        }
        let payload = weekData?.map((item,index)=>{
            return {...item,plandate:dateArr[index+1], mobile: localStorage.getItem('superonUserPhNum')}
        })
        if (showError) {
            setErrorObj({ ...error })
            console.log('show error', error)
            return
        }
        console.log('payload', payload,imprest)
        const newPayload = {"planner_details":payload,...imprest,mobile: localStorage.getItem('superonUserPhNum')}
        console.log('new payload',newPayload)
        // return
        setLoader(true)
        setShowLoading(true)
        try {
            const res = await axios.post(config.ipAddress + `/addweeklyplanner`,newPayload )
            const resultData = res?.data?.message
            setResult(resultData);
            const blankWeekData = weekData?.map((item)=>{
                return (
                    {...item, area_of_operation: '', ss_cwa_plan: '', mca_plan: '', stavinox_plan: '', dealer_customer_target: '', industrial_customer_visit_plan: '', remarks: ''}
                )
            })
            setWeekData(blankWeekData);

            // const blankImprest = imprest?.map((item) => {
            //     return (
            //         {...item, imprest_needed: 'No', previous_imprest_cleared: 'No'}
            //     )
            // })
            
            const blankImprest = {
                ...imprest,
                imprest_needed: 'No',
                previous_imprest_cleared: 'No',
            };
            
            setImprest(blankImprest);
            setShowLoading(false)
            setLoader(false)
            console.log('submit')
        } catch (error) {
            console.log('error')
            console.log(error)
            setMessage("Network Error!");
            setShowErrorModal(true);
            setShowLoading(false)
            setLoader(false)
        }
    }
    console.log('week data',weekData)
    const sendMobile = async ()=> {
        try {
            const res = await axios.post(config.ipAddress + `/checkweeklyplanner`, {mobile: localStorage.getItem('superonUserPhNum')})
            console.log('checkweeklyplanner: ',res?.data?.data?.exist)
            const result = res?.data?.data?.exist;
            setWeeklyPlanStatus(result);
        } catch (error) {
            console.log('checkweeklyplanner error: ',error)
        }
    }
    console.log('weekly Plan Status',weeklyPlanStatus)
    useEffect(()=>{
        sendMobile();
    },[result]);
    useEffect(()=>{
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const currentDayName = days[currentDay];
        setIsCurrentDay(currentDayName);
        // console.log('current Day',days[currentDay])
    },[isCurrentDay])
    console.log('isCurrentDay',isCurrentDay);
    return (
        <>
            <div className='bg-[#fafafa]  h-[89.2%] overflow-auto'>
                <div className='min-h-full px-3 py-3'>
                    <div className='rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white'>
                        <section className="flex justify-between items-center  border-b pb-4">
                            <div className="flex gap-2 pt-4 ml-4 ">
                                <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                                <div className="">
                                    <h1 className="text-lg ">
                                    {" "}
                                    Weekly Planing
                                    </h1>
                                    <p></p>
                                </div>
                            </div>
                        </section>

                        {weeklyPlanStatus === 0 ?
                            <div className='rounded-sm border-1 border-solid border-gray-400 pb-4 px-4 mt-4 custom-accodian'>
                                <ul>
                                    { weekData.map((item,index) => (
                                        <li
                                        className={`${collapsed==index+1 ? 'show' : ''}`}
                                        // style={{borderColor: (errorObj.hasOwnProperty(item?.weekday)) ? 'red':''}}
                                        style={{borderColor: (errorObj[item?.weekday] && Object.values(errorObj[item?.weekday]).some(value => value === true)) ? 'red':''}}
                                    >
                                        <div
                                            className='accordian-heading'
                                            onClick={() => handleOpen(index+1)}
                                        >{item?.weekday}</div>
                                        <div className="accordion-body">
                                            <div className="form-group">
                                                <div className="field">
                                                    <label>Area of Operation</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Town / City / District"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.area_of_operation ? "error-field" : ""}`}
                                                        // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                                        value={item?.area_of_operation}
                                                        name="area_of_operation"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                        }}
                                                    />
                                                </div>
                                                <div className="field">
                                                    {/* <label>SS CWA Activity Plan</label> */}
                                                    <label>SS CWA (Orange) Plan</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Dealers to be Visited / Retail are to be visited / Fabricator Area"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.ss_cwa_plan ? "error-field" : ""}`}
                                                        value={item?.ss_cwa_plan}
                                                        name="ss_cwa_plan"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],ss_cwa_plan:false}})
                                                        }}
                                                    />
                                                </div>
                                                <div className="field">
                                                    {/* <label>MCA Activity Plan</label> */}
                                                    <label>SS MCA (Yellow) Plan</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Dealers to be Visited / Industrial Consumer Area"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.mca_plan ? "error-field" : ""}`}
                                                        value={item?.mca_plan}
                                                        name="mca_plan"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],mca_plan:false}})
                                                        }}
                                                    />
                                                </div>
                                                <div className="field">
                                                    {/* <label>SS Stavinox Activity Plan</label> */}
                                                    <label>SS Stan (Blue) / Stavinox (Green) Plan</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Dealers to be Visited / Retail are to be visited / Fabricator Area"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.stavinox_plan ? "error-field" : ""}`}
                                                        value={item?.stavinox_plan}
                                                        name="stavinox_plan"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],stavinox_plan:false}})
                                                        }}
                                                    />
                                                </div>
                                                <div className="field">
                                                    <label>Stavinox New Amount Plan</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Dealers to be Visited / Retail are to be visited / Fabricator Area"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.dealer_customer_target ? "error-field" : ""}`}
                                                        value={item?.dealer_customer_target}
                                                        name="dealer_customer_target"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],dealer_customer_target:false}})
                                                        }}
                                                    />
                                                </div>
                                                <div className="field">
                                                    <label>New MCA / Industrial Customer Visit Plan</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Dealers to be Visited / Retail are to be visited / Fabricator Area"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.industrial_customer_visit_plan ? "error-field" : ""}`}
                                                        value={item?.industrial_customer_visit_plan}
                                                        name="industrial_customer_visit_plan"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],industrial_customer_visit_plan:false}})
                                                        }}
                                                    />
                                                </div>
                                                <div className="field !mb-0">
                                                    <label>Remarks</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Dealers to be Visited / Retail are to be visited / Fabricator Area"
                                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj[item?.weekday]?.remarks ? "error-field" : ""}`}
                                                        value={item?.remarks}
                                                        name="remarks"
                                                        onChange={(e)=> {
                                                            changeHandler(e,item,item?.weekday)
                                                            setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],remarks:false}})
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                                <div className="imprest">
                                    <div className="form-group">
                                        <div className="subheading">Imprest needed?</div>
                                        <label htmlFor="yes">
                                            <input
                                                type="radio"
                                                name="imprest_needed"
                                                id="yes"
                                                value="Yes"
                                                onChange={changeImprestHandler}
                                                checked={imprest?.imprest_needed==="Yes"}
                                            />
                                            Yes
                                        </label>
                                        <label htmlFor="no">
                                            <input
                                                type="radio"
                                                name="imprest_needed"
                                                id="no"
                                                value="No"
                                                onChange={changeImprestHandler}
                                                checked={imprest?.imprest_needed==="No"}
                                            />
                                            No
                                        </label>
                                        {/* <label htmlFor="not-requested">
                                            <input
                                                type="radio"
                                                name="previous"
                                                id="not-requested"
                                                value="Not Requested"
                                                onChange={changeImprestHandler}
                                                // checked="checked"
                                                checked={imprest?.previous_imprest_cleared==="Not Requested"}
                                             />
                                            Not Requested
                                        </label> */}
                                    </div>
                                    {imprest?.imprest_needed === "Yes" && 
                                    <div className="form-group">
                                        <div className="subheading">Cleared?</div>
                                        <label htmlFor="yes-cleared">
                                            <input
                                                type="radio"
                                                name="previous_imprest_cleared"
                                                id="yes-cleared"
                                                value="Yes"
                                                onChange={changeImprestHandler}
                                                checked={imprest?.previous_imprest_cleared==="Yes"}
                                            />
                                            Yes
                                        </label>
                                        <label htmlFor="no-cleared">
                                            <input
                                                type="radio"
                                                name="previous_imprest_cleared"
                                                id="no-cleared"
                                                value="No"
                                                onChange={changeImprestHandler}
                                                checked={imprest?.previous_imprest_cleared==="No"}
                                            />
                                            No
                                        </label>
                                    </div>
                                    }
                                    {imprest?.previous_imprest_cleared === "Yes" &&
                                    <>
                                        <div className="form-group">
                                            <div className="subheading">Number of Outstation Days</div>
                                            <input
                                                type="text"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj?.imprest?.number_of_outstation_days ? 'error-field':''}`}
                                                value={imprest?.number_of_outstation_days}
                                                name="number_of_outstation_days"
                                                placeholder='Type here'
                                                onChange={(e)=>{
                                                    changeImprestHandler(e)
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                    // setErrorObj({...errorObj,weekly_imprest_request:false})
                                                    setErrorObj({
                                                        ...errorObj,
                                                        imprest: {
                                                            ...errorObj.imprest,
                                                            number_of_outstation_days: false
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="subheading">Area of Operation</div>
                                            <input
                                                type="text"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj?.imprest?.area_of_operation ? 'error-field':''}`}
                                                value={imprest?.area_of_operation}
                                                name="area_of_operation"
                                                placeholder='Type here'
                                                onChange={(e)=>{
                                                    changeImprestHandler(e)
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                    // setErrorObj({...errorObj,weekly_imprest_request:false})
                                                    setErrorObj({
                                                        ...errorObj,
                                                        imprest: {
                                                            ...errorObj.imprest,
                                                            area_of_operation: false
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="subheading">Weekly Imprest Request</div>
                                            <input
                                                type="text"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errorObj?.imprest?.weekly_imprest_request ? 'error-field':''}`}
                                                value={imprest?.weekly_imprest_request}
                                                name="weekly_imprest_request"
                                                placeholder='Type here'
                                                onChange={(e)=>{
                                                    changeImprestHandler(e)
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                    // setErrorObj({...errorObj,weekly_imprest_request:false})
                                                    setErrorObj({
                                                        ...errorObj,
                                                        imprest: {
                                                            ...errorObj.imprest,
                                                            weekly_imprest_request: false
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                                <div class="flex flex-wrap justify-center items-center gap-2 px-1 w-[100px] mt-5 mx-auto border-[1px] border-gray-400 rounded-md  h-[34px] shadow-4xl ">
                                    <button
                                        class="text-[13px] text-[#AAAAAA]"
                                        onClick={handleSubmit}
                                        disabled={loader ? true : false}
                                    >
                                        {loader ? <img src={IconLoader} width="50" /> : 'Submit'}
                                    </button>
                                </div>
                            </div>
                            :
                            weeklyPlanStatus === 99 ?
                            <>
                                <div
                                    className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
                                    // onBlur={() => setIsModalOpen(false)}
                                >
                                    <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
                                </div>
                                {/* <div
                                // onClick={() => setIsModalOpen(false)}
                                className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
                                ></div> */}
                            </>
                            :
                            <p className='text-left py-3 px-4'>Your weekly plan has been submitted.</p>
                        }
                    </div>
                </div>
            </div>
            {showLoading && (
                <div
                className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
                // onBlur={() => setIsModalOpen(false)}
                >
                <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
                </div>
            )}
            {showLoading && (
                <div
                // onClick={() => setIsModalOpen(false)}
                className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
                ></div>
            )}
            {showErrorModal ? (
                <div
                className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
                // onBlur={() => setIsModalOpen(false)}
                >
                <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />

                {/* <button
                    className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
                    onClick={() => setIsModalOpen(false)}
                >
                    Close
                </button> */}
                </div>
            ) : (
                ""
            )}
            {showErrorModal ? (
                <div
                onClick={() => setShowErrorModal(false)}
                className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
                ></div>
            ) : (
                ""
            )}
        </>    
    )
}

export default WeeklyPlaning
