import React from 'react'
import "./Style.css"
import {Link} from "react-router-dom";

const Dealer = () => {
    return (
        <div className='bg-[#fafafa] h-[89.2%] overflow-auto'>
            <div className='min-h-full px-3 py-3'>
                <div className='rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white mt-3'>
                    <section className="flex justify-between items-center  border-b pb-4">
                        <div className="flex gap-2 pt-4 ml-4 ">
                            <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                            <div className="">
                                <h1 className="text-lg ">
                                {" "}
                                Dealers
                                </h1>
                                <p></p>
                            </div>
                        </div>
                    </section>
                    <div className="table-wrapper">
                        <table className="table min-w-[auto] w-[100%]">
                            <thead>
                                <tr>
                                    <th className='w-[55px] max-w-[55px] min-w-[55px] text-[14px] p-2'>S No.</th>
                                    {/* <th className='w-[110px] max-w-[110px] min-w-[110px] text-[14px] p-2'>Downladed At</th> */}
                                    <th className='w-[170px] max-w-[170px] min-w-[170px] text-[14px] text-left p-2'>Dealer Name</th>
                                    {/* <th className='text-[14px] p-2'>Prod. No.</th> */}
                                    <th className='text-[14px] p-2'></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center text-[14px] p-2'>1.</td>
                                    <td className='capitalize text-[14px] p-2'>Vikas Enterprise</td>
                                    <td className='text-[14px] p-2 text-center'>
                                        <Link to={"/dashboard/dealer/create-order"}>Create Order</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center text-[14px] p-2'>1.</td>
                                    <td className='capitalize text-[14px] p-2'>Vikas Enterprise</td>
                                    <td className='text-[14px] p-2 text-center'>
                                        <Link to={"#"}>Create Order</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center text-[14px] p-2'>1.</td>
                                    <td className='capitalize text-[14px] p-2'>Vikas Enterprise</td>
                                    <td className='text-[14px] p-2 text-center'>
                                        <Link to={"#"}>Create Order</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center text-[14px] p-2'>1.</td>
                                    <td className='capitalize text-[14px] p-2'>Vikas Enterprise</td>
                                    <td className='text-[14px] p-2 text-center'>
                                        <Link to={"#"}>Create Order</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center text-[14px] p-2'>1.</td>
                                    <td className='capitalize text-[14px] p-2'>Vikas Enterprise</td>
                                    <td className='text-[14px] p-2 text-center'>
                                        <Link to={"#"}>Create Order</Link>
                                    </td>
                                </tr>
                                {/* {   dealerDownloadList?.length ? 
                                    dealerDownloadList?.map((item,index)=>(
                                        <React.Fragment key={item?.id}>
                                            <tr>
                                                <td className='text-center text-[14px] p-2'>{index+1}.</td>
                                                <td className='capitalize text-center text-[14px] p-2'><span className='cursor-pointer'>{item?.batch_no}</span></td>
                                                <td className='text-[14px] p-2 text-center'><Link to={`${baseURL}static/tc_certificates/${item?.certificate}`} target='_blank'>View</Link></td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={3} className='text-center'>No Data Available</td>
                                    </tr>
                                } */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dealer
