import axios from 'axios';
import React, {useState, useEffect} from 'react'
import IconLoader from "../../assets/loading2.gif"
import moment from 'moment';
import { Link } from 'react-router-dom';
import config from '../Utils/config';
const Certificate = () => {
    const [certificateDownload,setCertificateDownload] = useState({
        batch_no: ''
    });
    const [batchNotFound,setBatchNotFound] = useState(false);
    const [errObj,setErrObj] = useState({
        batch_no: false
    })
    const [btnDisabled,setBtnDisabled] = useState(false)
    const [dealerDownloadList,setDealerDownloadList] = useState({})
    const handleChange = (e) => {
        const {name,value} = e.target;
        setCertificateDownload({
            ...certificateDownload,
            [name]: value
        })
        setBatchNotFound(false)
        setErrObj({
            ...errObj,
            [name]: false
        })
    }
    const baseURL = "https://testcertificateapi.superontechnik.com/";
    const downloadImage = async (response) => {
        const {certificate} = response.data
        try {
            const response = await fetch(config.ipAddress_Secound + `static/tc_certificates/${certificate}`)
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download",certificate ) // Set image name
            document.body.appendChild(link)
            link.click()
            link.remove()
        } catch (error) {
            console.error("Error downloading the image:", error)
        }
    }
    const userMobile = localStorage.getItem('superonUserPhNum')
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(certificateDownload?.batch_no === "") {
            setErrObj({
                ...errObj,
                batch_no: true
            })
            return
        }
        const data = certificateDownload?.batch_no;
        setBtnDisabled(true)
        try {
            // const response = await axios.get(config.ipAddress + `/tc-detail-app/${data}&?mobile=${userMobile}`)
            const response = await axios.get(config.ipAddress_Secound + `customer/api/v1/tc-detail-app/${data}/?mobile=${userMobile}`)
            console.log('res pose',response)
            // if()
            downloadImage(response)
            setBtnDisabled(false)
            // await queryClient.refetchQueries({ queryKey: ["dealerDownloadList"]})
            fetchDealerDownloadList()
            setCertificateDownload({
                ...certificateDownload,
                batch_no: ''
            })
            
        } catch (error) {
            console.log('error',error?.request?.status)
            if(error?.request?.status === 404) {
                setBatchNotFound(true)
            }
            setBtnDisabled(false)
        }
    }
    //setDealerDownloadList
    const fetchDealerDownloadList = async (id) => {
        const response = await axios.get(config.ipAddress_Secound + `customer/api/v1/tc-detail-list-dealer-app/?mobile=${userMobile}`)
        const res = response?.data?.results
        setDealerDownloadList(res)
    }
    useEffect(() => {
        fetchDealerDownloadList()
    },[])
    return (
        <div className='bg-[#fafafa] h-[89.2%] overflow-auto'>
            <div className='min-h-full px-3 py-3'>
                <div className='rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white'>
                    <section className="flex justify-between items-center  border-b pb-4">
                        <div className="flex gap-2 pt-4 ml-4 ">
                            <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                            <div className="">
                                <h1 className="text-lg ">
                                {" "}
                                Download Test Certificate
                                </h1>
                                <p></p>
                            </div>
                        </div>
                    </section>
                    <form className='rounded-sm border-1 border-solid border-gray-400 pb-4 px-4 mt-4 flex flex-wrap justify-between items-start'>
                        <div className="field w-[calc(100%_-_100px)]">
                            <label className='text-[14px] mb-2 block'>Batch No.</label>
                            <div className="property">
                                <input
                                    type="text"
                                    placeholder='Type here'
                                    name="batch_no"
                                    value={certificateDownload?.batch_no}
                                    onChange={handleChange}
                                    className='h-[34px] text-[14px] border-b border-solid border-gray-400 w-full'
                                />
                                {batchNotFound && <p className='error-text text-[#f00] text-[14px]'>Batch No. not found</p>}
                                {errObj?.batch_no && <p className='error-text'>Please enter Batch No.</p>}
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-start w-[100px] pl-2">
                            <label className='block min-h-[21px] w-full mb-2'></label>
                            <button
                                className="flex flex-wrap justify-center items-center gap-2 px-1 w-[100px] mx-auto border-[1px] border-gray-400 rounded-md h-[34px] shadow-4xl text-[13px] text-[#AAAAAA]"
                                onClick={handleSubmit}
                            >
                                {btnDisabled ? <img src={IconLoader} width="50" /> : "Download"}
                            </button>
                        </div>
                    </form>
                </div>
                <div className='rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white mt-3'>
                    <section className="flex justify-between items-center  border-b pb-4">
                        <div className="flex gap-2 pt-4 ml-4 ">
                            <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                            <div className="">
                                <h1 className="text-lg ">
                                {" "}
                                Downloaded Test Certificate
                                </h1>
                                <p></p>
                            </div>
                        </div>
                    </section>
                    <div className="table-wrapper">
                        <table className="table min-w-[auto] w-[100%]">
                            <thead>
                                <tr>
                                    <th className='w-[55px] max-w-[55px] min-w-[55px] text-[14px] p-2'>S No.</th>
                                    {/* <th className='w-[110px] max-w-[110px] min-w-[110px] text-[14px] p-2'>Downladed At</th> */}
                                    <th className='w-[170px] max-w-[170px] min-w-[170px] text-[14px] p-2'>Batch No.</th>
                                    {/* <th className='text-[14px] p-2'>Prod. No.</th> */}
                                    <th className='text-[14px] p-2'>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   dealerDownloadList?.length ? 
                                    dealerDownloadList?.map((item,index)=>(
                                        <React.Fragment key={item?.id}>
                                            <tr>
                                                <td className='text-center text-[14px] p-2'>{index+1}.</td>
                                                {/* <td className='capitalize text-center text-[14px] p-2'><span className='cursor-pointer'>{moment(item?.created_at).format('DD-MM-YYYY')}</span></td> */}
                                                <td className='capitalize text-center text-[14px] p-2'><span className='cursor-pointer'>{item?.batch_no}</span></td>
                                                {/* <td className='text-[14px]' title={item?.country}>{item?.production_no}</td> */}
                                                <td className='text-[14px] p-2 text-center'><Link to={`${baseURL}static/tc_certificates/${item?.certificate}`} target='_blank'>View</Link></td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={3} className='text-center'>No Data Available</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Certificate
