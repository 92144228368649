import React from 'react'
import "./Style.css"

const DropdownMenu = ({data, nameSelect, setShowDropDown, toggleDropDown}) => {
    const handleClick = (option) => {
        nameSelect(option)
    }
    return (
        <>
            <div className='dropdown'>
                <ul>
                    {data?.map((item)=>{
                        return <li
                            onClick={() => handleClick(item)}
                        >{item}</li>
                    })}
                </ul>
            </div>
        </>
    )
}

export default DropdownMenu
