import React, { useEffect, useState } from "react";
import leftArrow from "../assets/leftArrow.png";
import superonLogo from "../assets/superonlogo.png";
import callIcon from "../assets/call1.svg";
import loadingGif from "../assets/loading.gif";

import handLogo from "../assets/call2.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "./Utils/config";
import HelpModal from "./HelpModal";

function Pending() {
  const navigate = useNavigate();
  const goBackToPhoneInputField = () => {
    navigate("/phone");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen(false);
  };

  // handle user

  const handleUser = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    if (phoneNum) {
      const { data } = await axios.get(config.ipAddress + `/user/${phoneNum}`);

      console.log(data);
      localStorage.setItem("superonUserName", data?.data?.name);

      if (data.data.status === "0") {
        navigate("/createprofile");
      }
      if (data.data.status === "1") {
        navigate("/pending");
      }
      if (data.data.status === "2") {
        navigate("/dashboard/target");
      }
    } else {
      navigate("/phone");
    }
  };

  useEffect(() => {
    handleUser();
  }, []);

  return (
    <div className=" h-[100dvh] ">
      <div className="flex justify-between bg-orange-light p-6">
        {/* <img
          src={leftArrow}
          alt="left-arrow"
          className=""
          onClick={() => goBackToPhoneInputField()}
        /> */}
        <div></div>
        <img src={superonLogo} alt="menu-logo" className="w-[100px]" />
        <a href="tel:8527199811">
          <img
            src={callIcon}
            alt="help-logo"
            className="w-6"
            // onClick={() => setIsModalOpen(!isModalOpen)}
          />
        </a>
      </div>

      {/* <h2 className="px-6 text-white text-xl font-semibold">
        Please Request for Approval
      </h2> */}
      <div className="h-[86%]  bg-[#fafafa]  px-3 py-4">
        <div className="bg-white shadow-6xl h-full border-[1px] border-gray-300 rounded-[10px]">
          <div className="flex gap-2 px-6 pt-5">
            <div className="bg-[#f37022] w-[2px] "></div>
            <div className="">
              <h1 className="text-lg font-semibold">
                Please request for approval
              </h1>
            </div>
          </div>
          <div className="space-y-4 mt-32">
            <h1 className=" text-center text-xl font-semibold">Pending...</h1>

            <img src={loadingGif} alt="loading-gif" className="mx-auto  w-10" />
          </div>
        </div>
      </div>

      {isModalOpen ? (
        <div className="absolute z-20  text-center top-[20%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]">
          <HelpModal handleModal={handleModal} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-full bg-slate-300 absolute top-0 left-0 opacity-30 z-[1]"
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Pending;
