import React, {useState} from 'react'
import "../Style.css"
import { Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import AddItem from "../AddItem/Index"

const OrderCreate = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [isAdd,setAdd] = useState(false)

    const toggleDesc = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const closeForm = () => {
        setAdd(false)
    }

    const items = [
        {
            heading: "Stick Electrodes",
            desc: [
                {
                    label: "Carbon Steel Electrode",
                    link: "#"
                },
                {        
                    label: "Low Alloy Electrode",
                    link: "#",
                    product:[
                        {
                            label: "SUPER OPTIMAL 7010-G",
                            link: "#"
                        },
                        {
                            label: "Superon SUPER OPTIMAL 7016-A1",
                            link: "#",
                            product: [
                                {
                                    label: "Stainless Steel Electrode",
                                    link: "#"
                                },
                                {
                                    label: "Nickel-Copper Alloy Electrode",
                                    link: "#"
                                },
                            ]
                        },
                        {
                            label: "SUPER OPTIMAL 7018-A1",
                            link: "#"
                        },
                        {
                            label: "SUPER OPTIMAL 8018-G",
                            link: "#"
                        },
                        {
                            label: "SUPER OPTIMAL 9018-D1",
                            link: "#"
                        }
                        ,
                        {
                            label: "SUPER OPTIMAL 10018-D2",
                            link: "#"
                        },
                        {
                            label: "SUPER OPTIMAL 11018-G",
                            link: "#"
                        },
                        {
                            label: "SUPER OPTIMAL 12018-G",
                            link: "#"
                        }
                    ]

                },
                {
                    label: "Stainless Steel Electrode",
                    link: "#"
                },
                {
                    label: "Nickel-Copper Alloy Electrode",
                    link: "#"
                },
                {
                    label: "Cast Iron Electrode",
                    link: "#"
                },
                {
                    label: "Inconel Electrode",
                    link: "#"
                },
                {
                    label: "Hardfacing Electrode",
                    link: "#"
                },
            ]
        },
        {
            heading: "Welding Filler Wire",
            desc: [
                {
                    label: "Flux Cored Wires",
                    link: "#"
                },
                {
                    label: "Copper Coated Mild Steel MIG Wire",
                    link: "#"
                },
                {
                    label: "Stainless Steel Filler Wire",
                    link: "#"
                },
                {
                    label: "Nickel Alloy / Cast Iron",
                    link: "#"
                },
            ]
        },
        {
            heading: "Maintenance & Repair",
            desc: [
                {
                    label: "Repair - Joining Electrode",
                    link: "#"
                },
                {
                    label: "Cladding & Die Build Up Electrodes",
                    link: "#"
                },
                {
                    label: "CR-B Paste",
                    link: "#"
                },
                {
                    label: "Hard Surface Electrodes",
                    link: "#"
                },
                {
                    label: "Hard Surface Tubular Electrodes",
                    link: "#"
                },
                {
                    label: "Cast Iron Welding Electrodes",
                    link: "#"
                },
            ]
        },
    ];
    return (
        <>
        <div className='bg-[#fafafa] h-[89.2%] overflow-auto'>
            <div className='min-h-full px-3 py-3'>
                <div className='rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white mt-3'>
                    <section className="flex justify-between items-center  border-b pb-4">
                        <div className="flex gap-2 pt-4 ml-4 ">
                            <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                            <div className="">
                                <h1 className="text-lg">Dealer Order</h1>
                            </div>
                        </div>
                    </section>
                    <div className="accordian">
                        <ul>
                            {items.map((item, index) => (
                            <li key={index}>
                                <div
                                    className="list-heading"
                                    onClick={() => toggleDesc(index)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {item.heading}
                                </div>
                                {activeIndex === index &&
                                    <div className="desc">
                                        {item.desc.map((list, listIndex) => {
                                            return (
                                                <React.Fragment key={listIndex}>
                                                {
                                                    list?.product ?
                                                    <>
                                                        <div className='parent'><a href={list?.link}>{list.label}</a></div>
                                                        <div className='child'>
                                                            {list?.product.map((pro,proIndex) => {
                                                                return (
                                                                    <p
                                                                        key={proIndex}
                                                                        onClick={()=>setAdd(true)}
                                                                    >{pro?.label}</p>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                    :
                                                    <div className='parent'>
                                                        {/* <a href={list?.link}>{list.label}</a> */}
                                                        <span onClick={()=>setAdd(true)}>{list.label}</span>
                                                    </div>
                                                }
                                                </React.Fragment>
                                            )
                                            }
                                        )}
                                    </div>
                                }
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Drawer
            anchor='right'
            hideBackdrop={false}
            open={isAdd}
            onClose={closeForm}
        >
            <AddItem
                closeForm={closeForm}
            ></AddItem>
        </Drawer>
        </>
    )
}
export default OrderCreate