// import React, { useState } from "react";

// function CheckInForm() {
//   const [forms, setForms] = useState([]);

//   function handleAddClick() {
//     setForms([...forms, { showRemarks: false }]);
//   }

//   function handleCheckInClick(index) {
//     setForms(
//       forms.map((form, i) => {
//         if (i === index) {
//           return { ...form, showRemarks: true };
//         } else {
//           return form;
//         }
//       })
//     );
//   }

//   function handleCheckOutClick(index) {
//     setForms(
//       forms.map((form, i) => {
//         if (i === index) {
//           return { ...form, showRemarks: false };
//         } else {
//           return form;
//         }
//       })
//     );
//   }

//   return (
//     <div>
//       <button onClick={handleAddClick}>Add Form</button>

//       {forms.map((form, index) => (
//         <div key={index}>
//           <label htmlFor={`name-${index}`}>Name:</label>
//           <input type="text" id={`name-${index}`} name={`name-${index}`} />

//           {form.showRemarks ? (
//             <div>
//               <label htmlFor={`remarks-${index}`}>Remarks:</label>
//               <input
//                 type="text"
//                 id={`remarks-${index}`}
//                 name={`remarks-${index}`}
//               />

//               <button onClick={() => handleCheckOutClick(index)}>
//                 Check Out
//               </button>
//             </div>
//           ) : (
//             <button onClick={() => handleCheckInClick(index)}>Check In</button>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default CheckInForm;

// import React, { useState } from "react";

// function CheckInForm() {
//   const [forms, setForms] = useState([]);

//   function handleAddClick() {
//     setForms([...forms, { showRemarks: false }]);
//   }

//   function handleCheckInClick(index) {
//     setForms(
//       forms.map((form, i) => {
//         if (i === index) {
//           return { ...form, showRemarks: true };
//         } else {
//           return form;
//         }
//       })
//     );
//   }

//   function handleCheckOutClick(index) {
//     setForms(
//       forms.map((form, i) => {
//         if (i === index) {
//           return { ...form, showRemarks: false };
//         } else {
//           return form;
//         }
//       })
//     );
//   }

//   function handleCancelClick(index) {
//     setForms(forms.filter((form, i) => i !== index));
//   }

//   return (
//     <div>
//       <button onClick={handleAddClick}>Add Form</button>

//       {forms.map((form, index) => (
//         <div key={index}>
//           <label htmlFor={`name-${index}`}>Name:</label>
//           <input type="text" id={`name-${index}`} name={`name-${index}`} />

//           {form.showRemarks ? (
//             <div>
//               <label htmlFor={`remarks-${index}`}>Remarks:</label>
//               <input
//                 type="text"
//                 id={`remarks-${index}`}
//                 name={`remarks-${index}`}
//               />

//               <button onClick={() => handleCheckOutClick(index)}>
//                 Check Out
//               </button>
//               <button onClick={() => handleCancelClick(index)}>Cancel</button>
//             </div>
//           ) : (
//             <button onClick={() => handleCheckInClick(index)}>Check In</button>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default CheckInForm;

import React, { useState } from "react";

function CheckInForm() {
  const getTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const twelveHourFormat = hours % 12 || 12;

    const timeString = `${twelveHourFormat}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${amOrPm}`;

    return timeString;
  };

  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState([]);
  const [remark, setRemark] = useState("");

  function handleAddClick() {
    setForms([...forms, { showRemarks: false }]);
  }

  function handleCheckInClick(index) {
    setForms(
      forms.map((form, i) => {
        if (i === index) {
          return { ...form, showRemarks: true, checkedInTime: getTime() };
        } else {
          return form;
        }
      })
    );
  }

  function handleCheckOutClick(index) {
    setFormData([
      ...formData,
      {
        name: forms[index].dealerName || forms[index].type,
        checkedInTime: forms[index].checkedInTime,
        checkedOutTime: getTime(),
        Remarks: remark,
      },
    ]);

    setForms(
      forms.map((form, i) => {
        if (i === index) {
          return {
            ...form,
            checkedOutTime: getTime(),
            Remarks: remark,
            showRemarks: false,
          };
        } else {
          return form;
        }
      })
    );
    setForms(forms.filter((form, i) => i !== index));
  }

  function handleCancelClick(index) {
    setForms(forms.filter((form, i) => i !== index));
  }

  function handleNameChange(index, e) {
    setForms(
      forms.map((form, i) => {
        if (i === index) {
          return { ...form, dealerName: e.target.value };
        } else {
          return form;
        }
      })
    );
  }

  function handleSelectChange(index, e) {
    setForms(
      forms.map((form, i) => {
        if (i === index) {
          return { ...form, dealerName: e.target.value };
        } else {
          return form;
        }
      })
    );
  }

  console.log(forms);
  console.log(formData);
  // ? Note : formData is main data.

  return (
    <div className="py-4 px-5">
      <button onClick={handleAddClick}>Add Form</button>

      {forms.map((form, index) => (
        <div key={index}>
          {form.showRemarks ? (
            <div>
              {/* <label>Dealer Name:</label> */}
              <div className="flex justify-between items-center px-6 border-b border-gray-400">
                <h3 className="font-semibold">
                  {form.dealerName || form.type}
                </h3>
                {form.checkedInTime && (
                  <p className="text-center text-sm font-medium">
                    Punch In
                    <br />
                    <span>{form.checkedInTime.toLocaleString()}</span>
                  </p>
                )}
              </div>
              <br />
              <label
                htmlFor={`remarks-${index}`}
                className="text-md font-semibold "
              >
                Remarks:
              </label>
              <br />
              <textarea
                type="text"
                id={`remarks-${index}`}
                name={`remarks-${index}`}
                className="w-full p-1 px-3 mt-1 border-[1px] text-sm border-gray-400  focus:outline-none focus:border-[#f37022] rounded-[4px]"
                cols="30"
                rows="3"
                // placeholder="Add Remarks"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
              <div className="text-right mt-3 space-x-6">
                <button
                  onClick={() => handleCancelClick(index)}
                  className="border-[1px] border-gray-500 px-4 py-1 rounded-full shadow-sm"
                >
                  Cancel Visit
                </button>
                <button
                  onClick={() => handleCheckOutClick(index)}
                  className="border-[1px] border-gray-500 px-4 py-1 rounded-full shadow-sm"
                >
                  Check Out
                </button>
              </div>
            </div>
          ) : (
            <div>
              <select
                id={`type-${index}`}
                name={`type-${index}`}
                onChange={(e) => handleSelectChange(index, e)}
                className={
                  "flex w-full rounded-md border-solid border-2 box-border  border-gray-300  py-2 px-2 text-sm font-medium overflow-auto  focus:outline-none focus:border-[#f37022]"
                }
              >
                <option value="option1">Select Dealer Name</option>

                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>

              {/* <label htmlFor={`name-${index}`}>Delearname</label> <br /> */}
              <input
                type="text"
                id={`name-${index}`}
                name={`name-${index}`}
                onChange={(e) => handleNameChange(index, e)}
                className="mt-3 box-border w-full  border-solid border-b border-gray-300   py-2 px-3  text-sm font-normal placeholder:text-gray-400 focus:outline-none   focus:border-[#f37022] "
                placeholder="Add New Dealer Name"
              />

              <div className="text-center mt-3">
                <button
                  onClick={() => handleCheckInClick(index)}
                  className="border-[1px] border-gray-500 px-6 py-1 rounded-full"
                >
                  Check In
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default CheckInForm;

// card
{
  /* <div className="flex justify-center gap-8 bg-white px-4 py-3">
          <div>
            <p className="text-center text-lg text-orange-light">
              Punch In <br />
              <span className="font-medium text-black">9:05 AM</span>
            </p>
          </div>

          <div>
            <p className="text-center text-lg text-orange-light">
              Punch Out <br />
              <span className="font-medium text-black">9:05 PM</span>
            </p>
          </div>
        </div> */
}

{
  /* <div className="mt-4 bg-white px-4 py-3 rounded-[4px] shadow-lg">
          <h1 className="text-lg font-semibold text-orange-light mb-2">
            Visit 3 : <span className="text-black ml-3">Alex Hales</span>
          </h1>
          <div className="space-y-3 mb-2">
            <p className=" text-base text-black font-semibold">
              Timing :
              <span className="font-medium text-[#545454] ml-3">9:05 AM </span>{" "}
              - <span className="font-medium text-[#545454]">9:05 PM</span>
            </p>

            <p className=" text-base text-black font-semibold">
              <span className="font-medium  text-[#545454] ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
                ad est alias rem?
              </span>
            </p>
          </div>
          <hr className="h-[1px] bg-gray-400" />
        </div> */
}
