import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Dashboard/Navbar";
import axios from "axios";
import config from "../Utils/config";
import submitLogo from "../../assets/icon-submit.svg";
import loadingGif from "../../assets/loading2.gif";

import resetLogo from "../../assets/reset.svg";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./select.css";
import "./date.css";
import ErrorPopup from "../popupModal/ErrorPopup";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function ViewAttenddanceRecord() {
  const phoneNum = localStorage.getItem("superonUserPhNum");
  const navigate = useNavigate();

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [currentMonthName, setCurrentMonthName] = useState(
    new Date().getMonth() + 1
  );
  const [currentYearName, setCurrentYearName] = useState(
    new Date().getFullYear()
  );

  const [dailyWorkingDetails, setDailyWorkingDetails] = useState([]);
  const [dailyworkingHistory, setDailyWorkingHistory] = useState([]);

  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [message, setMessage] = useState("");

  // convert time && Date String
  const convertTime = (time) => {
    const hour = parseInt(time.slice(0, 2));
    const minute = time.slice(3, 5);
    const suffix = hour >= 12 ? "PM" : "AM"; // Determine the AM/PM suffix
    const formattedHour = hour % 12 || 12; // Convert the hour to 12-hour format

    const formattedTime = `${formattedHour}:${minute} ${suffix}`;
    console.log(formattedTime); // Output: "5:12 PM"
    return formattedTime;
  };

  function formatTime(timeString) {
    const dateString = timeString;
    const date = new Date(dateString);
    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    console.log(time); // Output: "3:34 PM"
    return time;
  }

  const covertDateString = (dateString) => {
    // const dateString = "2023-04-06T17:12:01";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const outputString = `${year}-${month}-${day}`;

    console.log(outputString); // Output: "2023-4-6"

    return outputString;
  };

  const convertDateStringForDate = (dateString) => {
    // const dateString = "2023-04-19T15:34:46";
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObj
    );
    // const year = dateObj.getFullYear().toString();
    const formattedDate = `${day}-${month}`;

    console.log(formattedDate); // Output: "19-Apr-2023"

    return formattedDate;
  };

  const getCurrentMonthAndYear = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    return { currentMonth, currentYear };
  };

  const { currentMonth, currentYear } = getCurrentMonthAndYear();

  const getDayName = (dayNum) => {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    return days[dayNum % 7];
  };

  const getSelectedWorkingDetails = async () => {
    if (!(selectedDateEnd && selectedDateStart)) {
      setMessage("Please select date!");
      setShowErrorModal(true);
      return;
    }
    function validateDates(from, to) {
      var fromDate = new Date(from);
      var toDate = new Date(to);

      if (toDate < fromDate) {
        return false;
      }

      return true;
    }
    let value = validateDates(selectedDateStart, selectedDateEnd);
    if (!value) {
      // return alert("please enter a valid date");

      setMessage("please enter a valid date");
      setShowErrorModal(true);
      return;
    }

    // if (
    //   !(month && year) ||
    //   month === "Select Month" ||
    //   year === "Select Year"
    // ) {
    //   return alert("Please Enter Month and Year");
    // }

    if (!phoneNum) {
      return;
    }

    setShowLoading(true);
    let data = {
      mobile: phoneNum,
      fromdate: covertDateString(selectedDateStart),
      todate: covertDateString(selectedDateEnd),
    };

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/attendance/history`,
        data
      );

      console.log(response?.data?.data);
      let allData = response?.data?.data;

      console.log(allData);
      let filterData = allData.filter((item) => {
        return item.punchouttime !== null;
      });

      setDailyWorkingHistory(response?.data?.data);
      setShowLoading(false);
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }
  };

  const getDailyWorkingHistory = async () => {
    setShowLoading(true);

    // Get the current date
    var currentDate = new Date();

    // Get the previous month's 1st date
    var previousMonthFirstDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );

    // Format the dates
    var currentDateString =
      currentDate.getFullYear() +
      "-" +
      (currentDate.getMonth() + 1) +
      "-" +
      currentDate.getDate();
    var previousMonthFirstDateString =
      previousMonthFirstDate.getFullYear() +
      "-" +
      (previousMonthFirstDate.getMonth() + 1) +
      "-" +
      previousMonthFirstDate.getDate();

    let data = {
      mobile: phoneNum,
      fromdate: previousMonthFirstDateString,
      todate: currentDateString,
    };
    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/attendance/history`,
        data
      );
      console.log(response?.data?.data);

      let allData = response?.data?.data;
      let filterData = allData.filter((item) => {
        return item.punchouttime !== null;
      });
      setDailyWorkingHistory(allData);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  const convertPunchTime = (timeString) => {
    // const timeString = "13:02:45";
    if (!timeString) return;
    const [hours, minutes] = timeString.split(":").map(Number);

    let amPm = "AM";
    let formattedHours = hours % 12;
    if (formattedHours === 0) {
      formattedHours = 12;
    }
    if (hours >= 12) {
      amPm = "PM";
    }

    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
    console.log(formattedTime);

    return formattedTime;
  };

  useEffect(() => {
    // getDailyWorkingDetails();
    getDailyWorkingHistory();
  }, []);

  // useEffect(() => {
  //   const selectElement = document.getElementById("monthSelect");
  //   for (let i = 0; i < selectElement.options.length; i++) {
  //     if (selectElement.options[i].value == currentMonthName) {
  //       selectElement.options[i].selected = true;
  //       break;
  //     }
  //   }
  // }, [currentMonthName]);
  // useEffect(() => {
  //   const selectElement = document.getElementById("yearSelect");
  //   for (let i = 0; i < selectElement.options.length; i++) {
  //     if (selectElement.options[i].value == currentYearName) {
  //       selectElement.options[i].selected = true;
  //       break;
  //     }
  //   }
  // }, [currentYearName]);

  return (
    <div className="h-[100dvh] overflow-auto bg-[#fafafa]">
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>

      <section className="bg-white min-h-[89.2%] px-3 py-4 ">
        {/* select month and year section */}
        <main className="min-h-full ">
          <div className="py-4  shadow-6xl rounded-[10px] border-[1px] border-gray-300">
            <div className="flex gap-2  ml-4">
              <div className="bg-[#f37022] w-[2px] rounded-full"></div>
              <div className="">
                <h1 className="text-lg ">DAR</h1>
              </div>
            </div>

            <div className="pt-4 pr-4 pl-[26px] bg-white">
              {/* <div className="flex justify-between items-center gap-4">
                <div className="w-1/2">
                
                  <select
                    id="monthSelect"
                    className="w-full rounded-md border-solid border-2 box-border  border-gray-300 py-2 px-3 text-sm font-medium overflow-auto  focus:outline-none focus:border-[#f37022] bg-white"
                    // value={currentMonthName}
                    onChange={(e) => setMonth(e.target.value)}
                  >
                    <option>Select Month</option>
                    {months.length &&
                      months.map((month, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={index + 1}>{month}</option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                </div>

                <div className="w-1/2 ">
                
                  <select
                    id="yearSelect"
                    className=" w-full  rounded-md border-solid border-2 box-border  border-gray-300  py-2 px-3 text-sm font-medium overflow-auto  focus:outline-none focus:border-[#f37022] bg-white"
                    // value={currentYearName}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option>Select Year</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </select>
                </div>
              </div> */}

              <div className="w-full flex justify-between gap-3">
                <Datepicker
                  // portalId="root-portal"
                  popperClassName="some-custom-class1"
                  popperPlacement="bottom"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [0, 0],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  selected={selectedDateStart}
                  onChange={(date) => setSelectedDateStart(date)}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="From"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                ></Datepicker>

                <Datepicker
                  portalId="root-portal"
                  popperClassName="some-custom-class2"
                  // popperModifiers={[
                  //   {
                  //     name: "offset",
                  //     options: {
                  //       offset: [5, 10],
                  //     },
                  //   },
                  //   {
                  //     name: "preventOverflow",
                  //     options: {
                  //       rootBoundary: "viewport",
                  //       tether: false,
                  //       altAxis: true,
                  //     },
                  //   },
                  // ]}
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: { behavior: ["bottom"] },
                    preventOverflow: { enabled: true },
                    hide: { enabled: false },
                  }}
                  selected={selectedDateEnd}
                  onChange={(date) => setSelectedDateEnd(date)}
                  dateFormat={"yyyy/MM/dd"}
                  placeholderText="To"
                  // showYearDropdown
                  wrapperClassName="datePicker"
                ></Datepicker>
              </div>
              {/* <div className="text-right mt-3">
              <button
                onClick={() => getSelectedWorkingDetails()}
                className="rounded-md w-[20%]  bg-gray-200 py-2 px-2 text-center text-sm  text-black mx-auto"
              >
                Submit
              </button>
            </div> */}

              {/* <div className="flex justify-end mt-2">
              <img
                src={submitLogo}
                alt="submit-logo"
                className="w-[18px]"
                onClick={() => getSelectedWorkingDetails()}
              />
            </div> */}
              <div className="flex justify-end gap-2 mt-4">
                <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
                  <img
                    src={resetLogo}
                    alt="submit-logo"
                    className="w-4 "
                    onClick={() => window.location.reload()}
                  />
                </div>
                <div className="border-[1px] flex justify-end  border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                  <img
                    src={submitLogo}
                    alt="submit-logo"
                    className="w-[18px] mx-auto "
                    onClick={() => getSelectedWorkingDetails()}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Punch in and out list */}
          <section
            className={`bg-white mt-4 ${
              dailyworkingHistory.length ? "pt-3" : "py-3"
            } rounded-[10px] shadow-6xl border-[1px] border-gray-200 `}
          >
            <div className="flex gap-2 py-2 pt-2 ml-4">
              <div className="bg-[#f37022] w-[2px] rounded-full"></div>
              <div className="">
                <h1 className="text-lg ">DAR Details</h1>
              </div>
            </div>
            {dailyworkingHistory.length
              ? dailyworkingHistory.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex justify-between items-center pb-3 mt-2 pr-4 pl-7 ">
                        <div className="bg-[#E9E9E9] py-2 px-3 rounded-md">
                          <p className="text-center text-sm w-10 ">
                            <span className="text-[11px]">
                              {getDayName(item.day)}
                              <br />
                              {convertDateStringForDate(item.created_at)}
                            </span>{" "}
                          </p>
                        </div>
                        <div>
                          <p className="text-center text-sm">
                            Punch In <br />{" "}
                            <span className="">
                              {convertPunchTime(item?.punchintime)}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="text-center text-sm">
                            Punch Out <br />
                            <span className="">
                              {convertPunchTime(item?.punchouttime) ||
                                "not created"}
                            </span>
                          </p>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              navigate(
                                `/viewdetails/${covertDateString(
                                  item.created_at
                                )}/${convertPunchTime(item?.punchintime)}/${
                                  convertPunchTime(item?.punchouttime) ||
                                  "not created"
                                }`
                              )
                            }
                            className="  rounded-full  text-blue-500"
                          >
                            View
                          </button>
                        </div>
                      </div>
                      {/* <hr className="h-[0px] bg-gray-400" /> */}
                    </React.Fragment>
                  );
                })
              : // <h1 className="text-lg font-bold ml-8 my-2">
                //   No Data to Display!
                // </h1>
                ""}

            {/* <div className="flex justify-between items-center pb-3 mt-2">
            <div className="bg-[#E9E9E9] py-2 px-3 rounded-md">
              <p className="text-center text-sm font-semibold">
                1 <br /> MON
              </p>
            </div>
            <div>
              <p className="text-center text-sm">
                Punch In <br /> <span className="font-medium">9:05 AM</span>
              </p>
            </div>
            <div>
              <p className="text-center text-sm">
                Punch Out <br />
                <span className="font-medium">9:05 PM</span>
              </p>
            </div>
            <div>
              <button className="border-[1px] border-gray-500 py-2 px-5 rounded-full">
                View
              </button>
            </div>
          </div>
          <hr className="h-[1px] bg-gray-400" />

          <div className="flex justify-between items-center pb-3 mt-2">
            <div className="bg-[#E9E9E9] py-2 px-3 rounded-md">
              <p className="text-center text-sm font-semibold">
                1 <br /> MON
              </p>
            </div>
            <div>
              <p className="text-center text-sm">
                Punch In <br /> <span className="font-medium">9:05 AM</span>
              </p>
            </div>
            <div>
              <p className="text-center text-sm">
                Punch Out <br />
                <span className="font-medium">9:05 PM</span>
              </p>
            </div>
            <div>
              <button className="border-[1px] border-gray-500 py-2 px-5 rounded-full">
                View
              </button>
            </div>
          </div>
          <hr className="h-[1px] bg-gray-400" /> */}
          </section>


          <div className='rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white mt-3'>
            <section className="flex justify-between items-center  border-b pb-4">
                <div className="flex gap-2 pt-4 ml-4 ">
                    <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                    <div className="">
                        <h1 className="text-lg ">
                        {" "}
                        Visit Status
                        </h1>
                        <p></p>
                    </div>
                </div>
            </section>
            <div className="table-wrapper">
                <table className="table min-w-[auto] w-[100%]">
                    <thead>
                        <tr>
                            <th className='w-[55px] max-w-[55px] min-w-[55px] text-[14px] p-2'>S No.</th>
                            {/* <th className='w-[110px] max-w-[110px] min-w-[110px] text-[14px] p-2'>Downladed At</th> */}
                            <th className='w-[170px] max-w-[170px] min-w-[170px] text-[14px] yp-2'>Dealer/Customer Name</th>
                            {/* <th className='text-[14px] p-2'>Prod. No.</th> */}
                            <th className='text-[14px] p-2'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {   dealerDownloadList?.length ? 
                            dealerDownloadList?.map((item,index)=>(
                                <React.Fragment key={item?.id}>
                                    <tr>
                                        <td className='text-center text-[14px] p-2'>{index+1}.</td>
                                        <td className='capitalize text-center text-[14px] p-2'><span className='cursor-pointer'>{item?.batch_no}</span></td>
                                        <td className='text-[14px] p-2 text-center'><Link to={`${baseURL}static/tc_certificates/${item?.certificate}`} target='_blank'>View</Link></td>
                                    </tr>
                                </React.Fragment>
                            ))
                            :
                            <tr>
                                <td colSpan={3} className='text-center'>No Data Available</td>
                            </tr>
                        } */}
                    </tbody>
                </table>
            </div>
          </div>
        </main>
      </section>

      {showErrorModal ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {showErrorModal ? (
        <div
          onClick={() => setShowErrorModal(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100%] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default ViewAttenddanceRecord;
