import React, { useEffect, useState } from 'react'
import crossIcon from "../../../assets/cross.svg"
import "../Drawer.css"
import { v4 as uuidv4 } from 'uuid'
import Dropdown from "../../Dropdown/Index"

const itemsCodesData = ["item-999","item-998","item-997","item-996","item-995"]
const itemsNameData = ["Supertrode A42","Superon 07","Super Optimal 7010","Super Optimal 7018(SP)","Superon 316L"]

const Index = ({closeForm}) => {
    const [showDropdown,setShowDropdown] = useState(false);
    const [itemsCode,setItemsCode] = useState(itemsCodesData);
    const [itemsName,setItemsName] = useState(itemsNameData);
    const [product,setProduct] = useState([
        {
            item_id: uuidv4(),
            item_code: "",
            item_name: "",
            item_qty: null
        }
    ])
    const addField = () => {
        setProduct([
            ...product,
            {
                item_id: uuidv4(),
                item_code: "",
                item_name: "",
                item_qty: null
            }
        ])
    }
    const removeField = (id) => {
        setProduct(product.filter((item)=>{
            return item?.item_id !== id
        }))
    }
    const handleChange = (e, id) => {
        const {name, value} = e.target;
        const newProduct = product.map((item) => {
            if(item.item_id == id) {
                return {
                    ...item,
                    [name]: name === "item_qty" ? Number(value) : value
                }
            }
            return item
        })
        console.log('product item',newProduct)
        setProduct(newProduct)
    }

    const handleQty = () => {}
    const minusQty = (e,qty,id) => {
        e.preventDefault();
        const updateQty = product.map((item) => {
            if(item?.item_id == id) {
                return {
                    ...item,
                    item_qty: qty >= 1 ? qty -1 : qty
                }
            }
            return item
        })
        setProduct(updateQty)
    }
    const plusQty = (e,qty,id) => {
        e.preventDefault();
        const updateQty = product.map((item) => {
            if(item?.item_id == id) {
                return {
                    ...item,
                    item_qty: qty + 1
                }
            }
            return item
        })
        setProduct(updateQty)
    }
    useEffect( () => {
        setItemsCode(itemsCodesData);
    },[showDropdown])
    console.log('items Code',itemsCode)
    return (
        <>
            <div className="drawer">
                <div className="drawer-header">
                    <div
                        className="flex flex-wrap items-center cursor-pointer drawer-heading"
                        onClick={() => closeForm()}
                    >
                        {/* <img alt="" src={ArrowLeft} className="mr-3" /> */}
                        <span>Add Item</span>
                    </div>
                </div>
                <div className="drawer-body">
                    {/* <div className="subheading">sdt</div> */}
                    <div className="drawer-form">
                        {
                            product.map((item) => (
                                <div key={item?.item_id} className='item-wrapper'>
                                    <div className='drawer-subheading relative flex flex-wrap items-center justify-between'>
                                        Item Details
                                        {product?.length > 1 &&
                                            <button
                                                onClick={() => removeField(item?.item_id)}
                                                className="cursor-pointer p-[2px]"
                                                style={{  }}
                                            ><img src={crossIcon} alt="Delete" width="14" className='!mx-auto' /></button>
                                        }
                                    </div>
                                    <div className="flex flex-wrap items-center justify-between mb-2">
                                        <label>Item Code</label>
                                        <div className="property">
                                            {/* <input
                                                type="text"
                                                name="item_code"
                                                value={item?.item_code}
                                                onChange={(e) => {
                                                        handleChange(e, item?.item_id)
                                                        setShowDropdown(true)
                                                    }
                                                }
                                            /> */}

                                            
                                            <Dropdown
                                                data={itemsCode}
                                                isSearch={true}
                                                placeholder="Select"
                                            ></Dropdown>
                                            
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap items-center justify-between mb-2">
                                        <label>Item Name</label>
                                        <div className="property">
                                            {/* <input type="text" /> */}
                                            <Dropdown
                                                data={itemsName}
                                                isSearch={true}
                                                placeholder="Select"
                                            ></Dropdown>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap items-center justify-between mb-2">
                                        <label>Qty</label>
                                        <div className="property flex flex-wrap justify-between">
                                            <button
                                                className='w-8 cursor-pointer bg-slate-100 border-[2px] rounded-lg'
                                                onClick={(e) => minusQty(e,item?.item_qty, item?.item_id)}
                                            >-</button>
                                            <input
                                                type="text"
                                                className='!w-28'
                                                name="item_qty"
                                                value={item?.item_qty}
                                                onChange={(e) => handleChange(e, item?.item_id)}
                                            />
                                            <button
                                                className='w-8 cursor-pointer bg-slate-100 border-[2px] rounded-lg'
                                                onClick={(e) => plusQty(e,item?.item_qty, item?.item_id)}
                                            >+</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        
                        <div className="flex flex-wrap justify-between">
                            <label></label>
                            <div
                                className="property add-more cursor-pointer"
                                onClick={addField}
                            >
                                + Add More
                            </div>
                        </div>
                    </div>
                </div>
                <div className="drawer-footer">
                    <button
                        onClick={() => closeForm()}
                        className="btn mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        // className={`btn-drawer ${submitLoader ? "!cursor-default":"!cursor-pointer"}`}
                        className='btn btn-primary'
                        // onClick={handleSubmit}
                        // disabled={submitLoader ? true: false}
                    >
                        {/* {submitLoader ? <CircleLoading />:'Submit'} */}
                        Submit
                    </button>
                </div>
            </div>
        </>
    )
}

export default Index