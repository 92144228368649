import React, { useEffect, useState } from "react";
import Navbar from "../../Dashboard/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../Utils/config";

function InvoiceDetails() {
  const params = useParams();
  console.log(params.number);

  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const getInvoiceDetails = async () => {
    let invoiceNum = params.number;
    let data = {
      docnum: invoiceNum,
    };

    let response = await axios.post(
      config.ipAddress + `/invoicedetail/${invoiceNum}`,
      data
    );
    console.log(response.data.data);
    setInvoiceDetails(response?.data?.data);
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);
  console.log(invoiceDetails[0]?.CardName);

  // convert Number with Comma
  const numberWithCommas = (numberStr) => {
    let num = Number(numberStr);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="bg-[#fafafa] h-screen box-border">
      {/* Navbar */}
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>

      <main className="px-4 pt-4 shadow-6xl min-h-full bg-white ">
        <section className="min-h-full">
          <section className="rounded-[10px] shadow-6xl py-4 border-[1px] border-gray-200">
            <div className="flex gap-2 mb-4 ml-4">
              <div className="bg-[#f37022] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Invoice Details</h1>
              </div>
            </div>
            <div className="">
              <table className="w-full text-sm text-left  text-gray-400">
                <thead className="text-xs text-black   bg-gray-300">
                  <tr className="">
                    <th scope="col" className="px-2 py-3  text-center">
                      Inv. No.
                    </th>

                    <th scope="col" className="px-2 py-3   text-center">
                      Inv. Date
                    </th>
                    <th scope="col" className="px-2 py-3  text-center">
                      Dealer
                    </th>

                    <th scope="col" className="px-2 py-3  text-center">
                      Amount
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="bg-white  text-black">
                    <td className="px-2 py-3 text-center">
                      {/* {numberWithCommas(invoiceDetails[0]?.DocNum)} */}
                      {invoiceDetails[0]?.DocNum}
                    </td>
                    <td className="px-2 py-3  text-[12px] text-center">
                      {invoiceDetails[0]?.CreateDate.split(" ")[0]}
                    </td>
                    <td className="px-2 py-3  text-[12px] w-[110px] text-center">
                      {invoiceDetails[0]?.CardName}
                    </td>

                    <td className="px-2 py-3   text-center">
                      ₹ {numberWithCommas(invoiceDetails[0]?.DocTotalSy)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className=" bg-white mt-4 rounded-[10px] shadow-6xl border-[1px] border-gray-200">
            <div className="flex gap-2 py-4 ml-4 ">
              <div className="bg-[#f37022] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Invoice Breakdown</h1>
              </div>
            </div>
            <div>
              <table className="w-full text-sm text-left text-gray-400">
                <thead className="text-xs text-black bg-gray-300">
                  <tr>
                    <th scope="col" className="px-2 text-center py-3 ">
                      Product
                    </th>
                    <th scope="col" className="px-2 text-center py-3 ">
                      Quantity
                    </th>
                    <th scope="col" className="px-2 text-center py-3 ">
                      Price
                    </th>

                    <th scope="col" className="px-2 text-center py-3 ">
                      Disc.
                    </th>

                    <th scope="col" className="px-2 text-center py-3 ">
                      Total
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {invoiceDetails.length
                    ? invoiceDetails.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="bg-white border-b border-gray-100 text-center text-black "
                          >
                            <td className="px-1 py-3  text-[12px] w-[110px] text-center">
                              {item.Dscription}
                            </td>
                            <td className="px-2 py-3  text-[12px] text-center">
                              {item.Quantity}
                            </td>

                            <td className="px-2 py-3 text-center">
                              ₹ {numberWithCommas(item.Price)}
                            </td>
                            <td className="px-2 py-3 text-center">
                              {item.DiscSum}
                            </td>
                            <td className="px-2 py-3 text-center ">
                              ₹ {numberWithCommas(item.LineTotal)}
                            </td>
                          </tr>
                        );
                      })
                    : ""}

                  {/* <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td className="px-2 py-4  text-[12px]">Pen</td>
                <td className="px-2 py-4  text-[12px]">100</td>

                <td className="px-2 py-4 text-center">500</td>
                <td className="px-2 py-4 text-center">50</td>
                <td className="px-2 py-4 text-center">450</td>
              </tr>

              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td className="px-2 py-4 text-[12px]">NoteBook</td>
                <td className="px-2 py-4  text-[12px]">100</td>

                <td className="px-2 py-4 text-center">6000</td>
                <td className="px-2 py-4 text-center">1000</td>
                <td className="px-2 py-4 text-center">5000</td>
              </tr>

              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td className="px-2 py-4 text-[12px]">Laptop</td>
                <td className="px-2 py-4  text-[12px]">100</td>

                <td className="px-2 py-4 text-center">5000000</td>
                <td className="px-2 py-4 text-center">20000</td>
                <td className="px-2 py-4 text-center">4980000</td>
              </tr> */}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </main>
    </div>
  );
}

export default InvoiceDetails;
