import React, { useState } from 'react'
import "./Style.css"
import DropdownMenu from "./DropdownMenu"

const Index = ({data, isSearch, placeholder}) => {
    const [showDropDown,setShowDropDown] = useState(false);
    const [formValue,setFormValue] = useState("");
    const toggleDropDown = () => {
        setShowDropDown(!showDropDown)
    }
    const nameSelect= (option) => {
        console.log("formValue", option)
        setFormValue(option)
    }
    console.log('items Code',data)
    return (
        <>
            <div
                className="dp-button"
                onClick={toggleDropDown}
            >
                <input
                    type="text"
                    placeholder={placeholder}
                    readOnly={isSearch}
                    value={formValue}
                />
                {showDropDown &&
                    <DropdownMenu
                        toggleDropDown={toggleDropDown}
                        setShowDropDown={setShowDropDown}
                        data={data}
                        nameSelect={nameSelect}
                    />
                }
            </div>
        </>
    )
}

export default Index
