import React, {useEffect, useState} from 'react'
import plusIcon from "../../assets/plusIcon.svg";
import "./Style.css"
import axios from 'axios';
import config from '../Utils/config';

const SummaryReport = () => {
    const [showAddButton, setShowAddButton] = useState(true);
    const [showCheckIn, setShowCheckIn] = useState(false);
    const [showLoading,setShowLoading] = useState(false);
    const [summary,setSummary] = useState({
        name: '',
        area_visited: '',
        retailer_visited: '',
        customer_visited: '',
        objective_visited: '',
        ss_c1_output: '',
        mca_output: '',
        w_aux_output: '',
        follow_up: ''
    });
    const [summaryData,setSummaryData] = useState([]);

    const [errObj,setErrObj] = useState({});

    function handleAddClick() {
        // setForms([...forms, { showRemarks: false }]);
        setShowCheckIn(true);
    }
    const handleChange = (e)=> {
        const {name,value} = e.target;
        setSummary({
            ...summary,
            [name]: value
        })
        setErrObj({
            ...errObj,
            [name]: false
        })
    }
    const handleResetSummary = () => {
        setSummary({
            name: '',
            area_visited: '',
            retailer_visited: '',
            customer_visited: '',
            objective_visited: '',
            ss_c1_output: '',
            mca_output: '',
            w_aux_output: '',
            follow_up: ''
        })
    }
    const handleSubmit = () => {
        setSummary({
            ...summary,
            name: summaryData?.length === 0 ? 'Self' : ''
        })
        let error = {};
        let showError = false;
        // for(let key in summary[i]) {
        //     if(!summary[i][key]) {
        //         showError = true
        //         const name = summary[i]
        //         error = {
        //             ...error,
        //             [name]: true
        //         }
        //     }
        // }
        for (let key in summary) {
            // Check if any value is empty
            if (!summary[key]) {
              showError = true;
              // Add the key to the error object
              error[key] = true;
              console.log(key)
            }
        }
        if(showError) {
            setErrObj(error)
            return
        }
        setShowLoading(true)
        const payload = {...summary, mobile: localStorage.getItem('superonUserPhNum')};
        console.log('payload',payload)
        console.log('summary error',errObj)
        
        try {
            const res = axios.post(config.ipAddress + '/adddsummary', payload)
            console.log('res',res)
            // setTimeout(() => {
            //     // window.scrollTo({ top: 0, behavior: 'smooth' });
            //     document.documentElement.scrollTop = 0;
            // }, 200);
            setShowCheckIn(false);
            handleResetSummary();
            setShowLoading(false)
            setTimeout(() => {
                window.location.reload();  // This will refresh the page
            }, 200);  // You can adjust the delay if needed
        } catch (error) {
            setShowLoading(false)
        }
    }
    const getSummary = async () => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const year = today.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        console.log('today',today, 'day',day, ' month', month, ' year',year)
        
        const data = {
            mobile:localStorage.getItem('superonUserPhNum'),
            fromdate:formattedDate,
            todate:formattedDate
        }
        try {
            // const response = await axios.get(config.ipAddress + `/getsummary?mobile=${localStorage.getItem('superonUserPhNum')}&fromdate=${formattedDate}&todate=${formattedDate}`)
            const response = await axios.post(config.ipAddress + `/getsummary`, data)
            console.log('data res new', response?.data?.data?.summaries)
            const summary = response?.data?.data?.summaries;
            setSummaryData(summary)
        } catch (error) {
            
        }
    }
    useEffect( ()=> {
        getSummary()
    },[])
    console.log('summaryData',summaryData)
    return (
        <>
            <div className=" bg-[#fafafa]  h-[89.2%] overflow-auto">
                <main className="  min-h-full px-3 py-3">
                    <div className="rounded-[10px]  border-[1px] border-gray-300  shadow-6xl bg-white">
                        <section className="flex justify-between items-center  border-b pb-4">
                            <div className="flex gap-2 pt-4 ml-4 ">
                                <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                                <div className="">
                                    <h1 className="text-lg">Summary Report</h1>
                                </div>
                            </div>
                            {showAddButton && (
                                <div className="mr-4 pt-4">
                                    <img
                                    src={plusIcon}
                                    alt="plus-icon"
                                    className="w-5"
                                    onClick={handleAddClick}
                                    />
                                </div>
                            )}
                        </section>
                    </div>

                    <section>
                    
                        <div
                            className={`${
                            showCheckIn ? "py-4 px-5 border-[1px]" : ""
                            } w-full bg-white rounded-[10px] mt-4 shadow-6xl  border-gray-300 `}
                        >
                            {showCheckIn && (
                            <div className=" bg-white ">
                                <div className="flex gap-2 bg-white mb-3">
                                <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                                    <div className="">
                                        <h1 className="text-md">Summary Report {summaryData?.length + 1}</h1>
                                    </div>
                                </div>

                                <div className="w-full mb-4 relative">
                                    <div className="field mb-4">
                                        <label className='block mb-2'>Name</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Name"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.name && 'error-field'}`}
                                            // value={summary?.name}
                                            value={summaryData?.length === 0 ? 'Self' : summary?.name}
                                            name='name'
                                            onChange={(e)=> handleChange(e)}
                                            disabled={summaryData?.length === 0 ? true : false}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>Area Visited</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Area Visited"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.area_visited && 'error-field'}`}
                                            value={summary?.area_visited}
                                            name='area_visited'
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>Retailer Visited</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Retailer Visited"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.retailer_visited && 'error-field'}`}
                                            value={summary?.retailer_visited}
                                            name='retailer_visited'
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>Customer Visited</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Customer Visited"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.customer_visited && 'error-field'}`}
                                            value={summary?.customer_visited}
                                            name='customer_visited'
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>Objective of Visit</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Objective of Visit"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.objective_visited && 'error-field'}`}
                                            value={summary?.objective_visited}
                                            name='objective_visited'
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>SS / CI Output</label>
                                        <input
                                            type="text"
                                            placeholder="Enter SS / CI Output"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.ss_c1_output && 'error-field'}`}
                                            value={summary?.ss_c1_output}
                                            name='ss_c1_output'
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>MCA Output</label>
                                        <input
                                            type="text"
                                            placeholder="Enter MCA Output"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.mca_output && 'error-field'}`}
                                            value={summary?.mca_output}
                                            name='mca_output'
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>W Aux Output</label>
                                        <input
                                            type="text"
                                            placeholder="Enter W Aux Output"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.w_aux_output && 'error-field'}`}
                                            value={summary?.w_aux_output}
                                            name="w_aux_output"
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                    <div className="field mb-4">
                                        <label className='block mb-2'>Follow Up Action Required</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Follow Up"
                                            className={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-2 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light ${errObj?.follow_up && 'error-field'}`}
                                            value={summary?.follow_up}
                                            name="follow_up"
                                            onChange={(e)=> handleChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button
                                        onClick={() => handleSubmit()}
                                        className={`text-sm px-1 w-[100px] border-[1px] border-gray-200 rounded-md  h-[34px] shadow-4xl mx-auto bg-[#f37022] text-[#fff]`}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            )}

                            
                        </div>
                        <div className="summary-report">
                            {summaryData?.length ?
                                summaryData?.map((item,index)=>{
                                    return <div
                                        key={index}
                                        className=" bg-white mt-4 rounded-[10px] shadow-6xl pl-4 border-[1px] border-gray-300"
                                    >
                                        <div className="flex flex-col gap-1 py-3   ">
                                            <div className="flex gap-2 bg-white mb-3">
                                                <div className="bg-[#f37022] w-[2px] rounded-full"></div>
                                                <div className="">
                                                    <h1 className="text-md ">Summary Report {index + 1}</h1>
                                                </div>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className="list-heading">Name</div>
                                                    <div className="desc">{item?.extra1}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">Area Visited</div>
                                                    <div className="desc">{item?.area_visited}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">Retailer Visited</div>
                                                    <div className="desc">{item?.retailer_visited}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">Customer Visited</div>
                                                    <div className="desc">{item?.customer_visited}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">Objective of Visit</div>
                                                    <div className="desc">{item?.objective_visited}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">SS / CI Output</div>
                                                    <div className="desc">{item?.ss_c1_output}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">MCA Output</div>
                                                    <div className="desc">{item?.mca_output}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">W Aux Output</div>
                                                    <div className="desc">{item?.w_aux_output}</div>
                                                </li>
                                                <li>
                                                    <div className="list-heading">Follow Up Action Required</div>
                                                    <div className="desc">{item?.extra2}</div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                })
                            :
                            ""
                            }
                        </div>
                        
                        
                    </section>
                </main>
                {showLoading && (
                    <div
                    // onClick={() => setIsModalOpen(false)}
                    className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
                    ></div>
                )}
            </div>
        </>
    )
}

export default SummaryReport
